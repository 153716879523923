import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import './WalletConnect.css'

const WalletConnect = ({ defaultAccountChange }) => {
    const [defaultAccount, setDefaultAccount] = useState(null)
    const [correctNetwork, setCorrectNetwork] = useState(null);
    const [connectButtonText, setConnectButtonText] = useState('Connect Wallet')
    const [isSet, setIsSet] = useState(false)

    useEffect(() => {
        changingAccount();
        defaultAccountChange(defaultAccount);
    }, [defaultAccount])

    async function changingAccount() {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', () => {
                connectWalletHandler()
            })
        }
    }

    const connectWalletHandler = async () => {
        if (window.ethereum) {
            window.ethereum.request({ method: 'eth_requestAccounts' })
                .then(async (result) => {
                    await accountChangeHandler(result[0]);
                    setConnectButtonText(`${result[0].slice(0, 4)}...${result[0].slice(-4)}`);
                    sessionStorage.setItem('account', result[0]);
                })
        } else {
            alert('Need to install MetaMask!')
        }
    }

    const accountChangeHandler = async (newAccount) => {
        checkCorrectNetwork();
        setDefaultAccount(newAccount);
    }

    const checkCorrectNetwork = async () => {
        const { ethereum } = window
        let chainId = await ethereum.request({ method: 'eth_chainId' })
        console.log('Connected to chain:' + chainId)

        const netWorkID = '0x42'

        if (chainId !== netWorkID) {
            setCorrectNetwork(false)
            console.log("Please Connect to the Correct Network")
        } else {
            setCorrectNetwork(true)
        }
    }

    const checkAccount = () => {
        if(isSet === true) return;
        let result = sessionStorage.getItem('account')
        if (result !== null) {
            connectWalletHandler()
            setIsSet(true)
        }
    }

    checkAccount();

    return (
        <div className="btnConnect">
            <button
                onClick={connectWalletHandler}
                className="buttonConnect"
                style={
                    {
                        position: 'fixed',
                        width: '120px',
                        height: '40px',
                        backgroundColor: 'transparent',
                        color: 'white',
                        right: '10vw',
                        top: '4vh',
                        border: '0px solid white',
                        borderRadius: '0px',
                        zIndex: '3',
                        fontSize: '13px',
                        paddingLeft: '40px',
                        paddingRight: '20px',
                    }
                }
            >{connectButtonText}</button>
        </div>
    )
}

export default WalletConnect
