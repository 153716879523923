import React from 'react'

const Bottom = () => {
  return (
    <div style={{ background: 'white', height: '20vh', color: 'transparent', display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }}>
      <p style={{ color: 'gray', fontSize: '12px', }}>
        免責聲明：<br />
        本工具不上傳任何私鑰信息，僅作為一協助工具使用，如有提供任何信息皆並非投資建議
      </p>
      <p style={{ fontSize: '12px', color: 'gray' }}>
        <a href="https://t.me/RyoLin" style={{ textDecoration: 'none', color: 'gray' }}>@RyoLin.</a> All rights reserved<br />
      </p>
    </div>
  )
}

export default Bottom
