import React, { useState, useEffect } from 'react'
import Web3 from 'web3'
import { ethers } from 'ethers'
import contractAbi from '../abi/tokenABI.json'
import swal from 'sweetalert'
import Bottom from '../components/Bottom'
import Loading from '../components/Loading'

// let BSCMainnetProvider = 'https://mainnet-rpc.hashbit.org';
let BSCMainnetProvider = 'https://bsc-dataseed.binance.org/';
let web3 = new Web3(new Web3.providers.HttpProvider(BSCMainnetProvider));


const BatchTransferPublic = ({ defaultAccount }) => {
    const [balance, setBalance] = useState(0);
    const [publicAddress, setPublicAddress] = useState("尚未導入地址");
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [totalError, setTotalError] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [tokenName, setTokenName] = useState(null);
    const [tokenDecimals, setTokenDecimals] = useState(null);

    const getBalance = async (value) => {
        let result = await web3.eth.getBalance(value)
        let tempEther = web3.utils.fromWei(result, 'ether');
        setBalance(tempEther);
    }

    useEffect(() => {
        setPublicAddress(defaultAccount);
        getBalance(defaultAccount);
    }, [defaultAccount])

    if (defaultAccount === undefined || defaultAccount === null)
        return <div style={{ position: 'fixed', top: '40vh', left: '40vw' }}>請先連結錢包</div>;

    const makeTransfer = async () => {
        if (errorMessage !== null) {
            swal("異常", "請先排除錯誤")
            return;
        }

        let result = document.getElementById('etherNumber').value
        if (result <= 0) {
            swal("異常", `轉帳數量不可為 ${result}`)
            return;
        }

        let addressResult = document.getElementById('addressList');
        let lines = addressResult.value.split('\n')
        if (lines[0].length === 0) {
            swal("異常", "無接收款項地址", "error")
            return;
        }

        setIsLoading(true);
        // const gasPrice = await web3.eth.getGasPrice()
        // let etherAmount = ethers.utils.parseEther(result);
        // let senderAccount = web3.eth.accounts.privateKeyToAccount(privateKey)
        // console.log(senderAccount.address);

        // let tempGas = lines.length * 40000;

        // let encodeABI = Contract.methods.batchTransferEther(lines).encodeABI();
        // console.log(encodeABI);
        // console.log("0xadf548f900000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000000003000000000000000000000000dbbb20493701b628b2964c44f29c6af67f705945000000000000000000000000cfba519c1baaf17bf7fd32ea944d21da7e4295dd000000000000000000000000e5c282b9379d4863f0dbf5e47b6bfcedd654fabf")

        // const tx = {
        //     from: senderAccount.address,
        //     to: contractAddress,
        //     value: etherAmount,
        //     gasPrice: gasPrice,
        //     gas: tempGas.toString(),
        //     data: encodeABI,
        // }

        // await web3.eth.accounts.signTransaction(tx, privateKey).then((signed) => {
        //     web3.eth.sendSignedTransaction(signed.rawTransaction).then(() => {
        //         setIsLoading(false);
        //         let tempMessage = `Tx Sent !! : ${signed.transactionHash}`
        //         swal("成功", tempMessage)
        //     })
        // })
    }



    const deleteFault = () => {
        let result = document.getElementById('addressList');
        let lines = result.value.split('\n')
        let tempAddressList = []
        let tempAddressExist = new Map();

        lines.forEach((line, index) => {
            if (line.length === 42) {
                let checkSumAddress = web3.utils.toChecksumAddress(line);
                if (tempAddressExist.get(checkSumAddress) === undefined) {
                    tempAddressExist.set(checkSumAddress, true)
                    tempAddressList.push(checkSumAddress)
                }
            }
        });
        let tempString = ""

        tempAddressList.map((item, index) => {
            if (index !== tempAddressList.length - 1)
                tempString = tempString.concat(`${item}\n`)
            else
                tempString = tempString.concat(`${item}`)
        })

        result.value = tempString;
        setTotalError(0)
        setErrorMessage(null);
    }

    const checkContract = async () => {
        let contractAddress = document.getElementById('tokencontract').value
        let tempCA = new web3.eth.Contract(contractAbi, contractAddress)
        console.log(tempCA)

        let tempName = await tempCA.methods.name().call();
        let tempSymbol = await tempCA.methods.symbol().call();
        let tempDecimals = await tempCA.methods.decimals().call();
        setTokenName(`${tempName}(${tempSymbol})`)
        setTokenDecimals(tempDecimals);
    }

    const handleTextareaChange = (value) => {
        const lines = value.target.value.split('\n');

        if (lines.length === 1) {
            // 如果沒資料的時候
            if (lines[0].length === 0) {
                setTotalError(0)
                setErrorMessage(null);
                return;
            }
        }
        let errorList = []
        let tempAddressExist = new Map();
        let errorCounter = 0;

        lines.forEach((line, index) => {
            if (line.length !== 42) {
                let tempError = `Line ${index + 1}: ${line} : 無效的錢包地址(長度錯誤)`
                errorList.push(tempError)
                errorCounter++;
            } else {
                try {
                let checkSumAddress = web3.utils.toChecksumAddress(line)
                    if (tempAddressExist.get(checkSumAddress) !== undefined) {
                        let tempError = `Line ${index + 1}: ${checkSumAddress} : 重複的錢包地址`
                        errorCounter++;
                        errorList.push(tempError)
                    }
                    if (tempAddressExist.get(checkSumAddress) === undefined)
                        tempAddressExist.set(checkSumAddress, true)
                } catch {
                    let tempError = `Line ${index + 1}: ${line} : 無效的錢包地址(拼寫錯誤)`
                    errorList.push(tempError)
                    errorCounter++;
                }
            }
            if (errorCounter === 0) {
                setTotalError(0)
                setErrorMessage(null);
            } else {
                setTotalError(errorCounter);
                setErrorMessage(errorList)
            }
        });
    }

    return (
        <div style={{
            width: '100vw',
            color: 'black',
            position: 'absolute',
            top: '15vh',
            height: '85vh',
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '40vh',
        }}>
            <p style={{ fontSize: '40px', fontWeight: 'bolder' }}>
                批量轉帳代幣功能
            </p>
            <p style={{ fontSize: '12px', color: 'gray', display: 'flex', marginLeft: '20px' }}>
                此為使用公鑰版本<br />
                須由使用者自身簽屬交易
            </p>
            <div className='transferWrapper' style={{ width: '80vw', maxWidth: '800px', height: '50vh' }}>
                <div className='addressData' style={{ border: '1px solid black' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', wordWrap: 'break-word' }}>
                        <p>您的地址 : {publicAddress} </p>
                        <p>BNB餘額 : {balance} </p>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                        <p>請輸入代幣合約地址</p>
                        <input type="text" id="tokencontract" placeholder="代幣合約地址" style={{ margin: '10px' }} />
                        <button onClick={checkContract}>確認合約</button>
                    </div>
                    {
                        tokenName !== null &&
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                            <p>
                                代幣名稱：{tokenName}<br/>
                                代幣精度：{tokenDecimals}<br/>
                            </p>
                            
                        </div>
                    }
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                        <p>轉帳總數量</p>
                        <input type="number" id="etherNumber" placeholder="地址平均分配" style={{ margin: '10px' }} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <p style={{ fontSize: '15px', color: 'gray', textAlign: 'left', flex: '1', padding: '5px' }}>
                        請輸入欲收取 "代幣" 之地址<br />
                        一行一地址
                    </p>
                    <button onClick={makeTransfer}>進行轉帳</button>
                </div>
                {
                    totalError !== 0 &&
                    <div
                        style={{
                            borderRadius: '5px',
                            border: '1px solid red',
                            width: '80vw',
                            maxWidth: '800px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            color: 'red',
                            fontSize: '12px',
                            textAlign: 'left',
                            paddingLeft: '10px'
                        }}>
                        <p>
                            總共有 {totalError} 個錯誤
                            {errorMessage.map(item => (
                                <li key={item}>{item}</li>
                            ))}
                        </p>
                        <p style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={deleteFault}>一鍵刪除無效地址</p>
                    </div>
                }
                {
                    isLoading === true &&
                    <div>
                        <Loading />
                    </div>
                }
                <div style={{ position: 'relative' }}>
                    <textarea
                        rows="10"
                        id='addressList'
                        style={{
                            border: '1px solid black',
                            width: '80vw',
                            maxWidth: '800px',
                        }}
                        onChange={handleTextareaChange}
                        placeholder="請輸入欲收取 [平台幣] 之地址，一行一地址"
                    />
                </div>
                <Bottom />
            </div>
        </div>
    )
}

export default BatchTransferPublic
