import React, { useState } from 'react'
import Web3 from 'web3'
import { ethers } from 'ethers'
import swal from 'sweetalert'
import Bottom from '../components/Bottom'
import Loading from '../components/Loading'

let BSCMainnetProvider = 'https://mainnet-rpc.hashbit.org';
// let BSCMainnetProvider = 'https://bsc-dataseed.binance.org/';
let web3 = new Web3(new Web3.providers.HttpProvider(BSCMainnetProvider));

const netNow = "HBIT"

//0x852ceeac91d1fc805ac0c9834ec2ff32f904090a09153d80e8cfc4e757113e72
//0x576584Fce0Ec4f1519E90e0B29c89eC705acFC68
const TransferEtherBack = () => {
    const [privateKey, setPrivateKey] = useState(null)
    const [balance, setBalance] = useState(0);
    const [publicAddress, setPublicAddress] = useState("尚未導入地址");
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [totalError, setTotalError] = useState(0);
    const [successList, setSuccessList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getPublicKey = async () => {
        let result = document.getElementById('publicKey').value

        try {
            let checkSum = web3.utils.toChecksumAddress(result)
            if (web3.utils.isAddress(checkSum)) {
                setPublicAddress(checkSum)
                let tempBal = await web3.eth.getBalance(checkSum);
                const etherBalance = web3.utils.fromWei(tempBal, 'ether');
                setBalance(etherBalance)
                swal("成功", "收款地址已成功導入")
            }
        } catch {
            swal("異常", "請導入正確地址")
        }
    }

    const makeTransfer = async () => {
        if (errorMessage !== null) {
            swal("異常", "請先排除錯誤", "error")
            return;
        }
        if (publicAddress === "尚未導入地址") {
            swal("異常", "請先輸入收款地址", "error")
            return;
        }
        let result = document.getElementById('addressList');
        let lines = result.value.split('\n')
        if (lines[0].length === 0) {
            swal("異常", "無發送款項地址", "error")
            return;
        }


        setIsLoading(true);

        const gasPrice = await web3.eth.getGasPrice()
        let tempGas = 21000;
        let gasUsed = gasPrice * tempGas;

        let tempSuccessList = [];

        lines.forEach(async (line, index) => {
            let senderAccount = web3.eth.accounts.privateKeyToAccount(line)
            let senderBal = await web3.eth.getBalance(senderAccount.address);
            if (senderBal < gasUsed) { }
            else {
                let transferAmount = senderBal - gasUsed
                const tx = {
                    from: senderAccount.address,
                    to: publicAddress,
                    value: transferAmount,
                    gasPrice: gasPrice,
                    gas: tempGas.toString(),
                }
                try {
                    await web3.eth.accounts.signTransaction(tx, line).then((signed) => {
                        web3.eth.sendSignedTransaction(signed.rawTransaction).then(() => {
                            let tempMessage = `交易成功 哈希值為: ${signed.transactionHash}`
                            console.log(tempMessage)
                            tempSuccessList.push(tempMessage)
                            if (index === lines.length - 1)
                                setIsLoading(false)
                        })
                    })
                } catch {
                    console.log("失敗")
                }
            }
        })
        setSuccessList(tempSuccessList);
    }

    const deleteFault = () => {
        let result = document.getElementById('addressList');
        let lines = result.value.split('\n')
        let tempAddressList = []
        let tempAddressExist = new Map();

        lines.forEach((line, index) => {
            try {
                web3.eth.accounts.privateKeyToAccount(line)
                if (tempAddressExist.get(line) === undefined) {
                    tempAddressExist.set(line, true)
                    tempAddressList.push(line)
                }
            } catch {

            }
        });
        let tempString = ""

        tempAddressList.map((item, index) => {
            if (index !== tempAddressList.length - 1)
                tempString = tempString.concat(`${item}\n`)
            else
                tempString = tempString.concat(`${item}`)
        })

        result.value = tempString;
        setTotalError(0)
        setErrorMessage(null);
    }

    const handleTextareaChange = (value) => {
        const lines = value.target.value.split('\n');
        console.log(lines[0].length)
        if (lines.length === 1) {
            // 如果沒資料的時候
            console.log(lines[0].length)
            if (lines[0].length === 0) {
                setTotalError(0)
                setErrorMessage(null);
                return;
            }
        }
        let errorList = []
        let tempAddressExist = new Map();
        let errorCounter = 0;

        lines.forEach((line, index) => {
            try {
                web3.eth.accounts.privateKeyToAccount(line)
                if (tempAddressExist.get(line) !== undefined) {
                    let tempError = `Line ${index + 1}: ${line} : 重複的私鑰地址`
                    errorCounter++;
                    errorList.push(tempError)
                }
                if (tempAddressExist.get(line) === undefined) {
                    tempAddressExist.set(line, true)
                }
            } catch {
                let tempError = `Line ${index + 1}: ${line} : 無效的私鑰地址`
                errorList.push(tempError)
                errorCounter++;
            }
            if (errorCounter === 0) {
                setTotalError(0)
                setErrorMessage(null);
            } else {
                setTotalError(errorCounter);
                setErrorMessage(errorList)
            }
        });
    }
    return (
        <div style={{
            width: '100vw',
            color: 'black',
            position: 'absolute',
            top: '15vh',
            height: '85vh',
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '40vh',
        }}>
            <p style={{ fontSize: '40px', fontWeight: 'bolder' }}>
                批量平台幣歸集
            </p>
            <p style={{ fontSize: '20px', color: 'gray' }}>
                (BSC鍊： 平台幣為 BNB)<br />
                (OKC鍊： 平台幣為 OKT)<br />
            </p>
            <p style={{ fontSize: '12px', color: 'black', display: 'flex', marginLeft: '20px' }}>
                當前網路:{netNow} <br />
                當前節點:{BSCMainnetProvider}
            </p>
            <div className='transferWrapper' style={{ width: '80vw', maxWidth: '800px', height: '50vh' }}>
                <div className='addressData' style={{ border: '1px solid black' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                        <p>輸入收取平台幣地址 : </p>
                        <input type="text" id="publicKey" placeholder="收幣地址" style={{ margin: '10px' }} />
                        <button onClick={getPublicKey}>確認地址</button>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', wordWrap: 'break-word' }}>
                        <p style={{ wordWrap: 'break-word', width: '80vw' }}>收款地址 : {publicAddress} </p>
                        <p >平台幣餘額 : {balance} </p>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <p style={{ fontSize: '15px', color: 'gray', textAlign: 'left', flex: '1', padding: '5px' }}>
                        請輸入欲歸集 "平台幣" 之地址私鑰<br />
                        一行一私鑰
                    </p>
                    <button onClick={makeTransfer}>進行轉帳</button>
                </div>
                {
                    totalError !== 0 &&
                    <div
                        style={{
                            borderRadius: '5px',
                            border: '1px solid red',
                            width: '80vw',
                            maxWidth: '800px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            color: 'red',
                            fontSize: '12px',
                            textAlign: 'left',
                            paddingLeft: '10px'
                        }}>
                        <p>
                            總共有 {totalError} 個錯誤
                            {errorMessage.map(item => (
                                <li key={item}>{item}</li>
                            ))}
                        </p>
                        <p style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={deleteFault}>一鍵刪除無效地址</p>
                    </div>
                }
                <div style={{ position: 'relative' }}>
                    <textarea
                        rows="10"
                        id='addressList'
                        style={{
                            border: '1px solid black',
                            width: '80vw',
                            maxWidth: '800px',
                        }}
                        onChange={handleTextareaChange}
                        placeholder="請輸入欲歸集 [平台幣] 之地址私鑰，一行一私鑰"
                    />
                </div>
                {
                    successList !== null &&
                    <div style={{ width: '80vw', maxWidth: '800px', overflow: 'scroll' }}>
                        {
                            successList.forEach((item, index) => (
                                <div
                                    style={{
                                        textAlign: 'left',
                                        paddingLeft: '25px',
                                        marginTop: '10px',
                                        fontSize: '12px'
                                    }}
                                >{index + 1} : {item}</div>
                            ))
                        }
                    </div>
                }
                {
                    isLoading === true &&
                    <div>
                        <Loading />
                    </div>
                }
                <Bottom />
            </div>
        </div>
    )
}

export default TransferEtherBack
