import React, { useState } from 'react'
import Bottom from '../components/Bottom'
import Web3 from 'web3'
import swal from 'sweetalert';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const web3 = new Web3();

const GenerateWallet = () => {

  const [privateKeyList, setPrivateKeyList] = useState(null);
  const [allWalletSelected, setAllWalletSelected] = useState(true);
  const [allPrivateSelected, setAllPrivateSelected] = useState(false);
  const [allAddressSelected, setAllAddressSelected] = useState(false);

  const [allPrivateKey, setAllPrivateKey] = useState(null)
  const [allAddress, setAllAddress] = useState(null)
  const [copied, setCopied] = useState(false);
  const [addressCopied, setAddressCopied] = useState(false);
  const [privateKeyCopied, setPrivateKeyCopied] = useState(false);

  const copyToClipBoard = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      if (value.length == 42)
        swal("成功", `已成功複製地址 ${value.slice(0, 4)}...${value.slice(-4)}`, "success")
      else
        swal("成功", `已成功複製私鑰 ${value.slice(0, 4)}...${value.slice(-4)}`, "success")
    }, (err) => {
      swal("異常", "複製失敗", "error")
    })
  }

  const copyAllAddress = () => {
    navigator.clipboard.writeText(allAddress).then(() => {
      swal("成功", `已成功複製所有地址`, "success")
    }, (err) => {
      swal("異常", "複製失敗", "error")
    })
  }

  const allWalletClicked = () => {
    setAllWalletSelected(true)
    setAllPrivateSelected(false)
    setAllAddressSelected(false)
  }
  const allPrivateClicked = () => {
    setAllWalletSelected(false)
    setAllPrivateSelected(true)
    setAllAddressSelected(false)
  }
  const allAddressClicked = () => {
    setAllWalletSelected(false)
    setAllPrivateSelected(false)
    setAllAddressSelected(true)
  }
  const copyAllPrivateKey = () => {
    swal("成功", "已成功複製所有私鑰", "success")
  }
  const generateWallet = () => {
    let value = document.getElementById('walletNumber').value
    if (value < 1 || String(value).includes(".")) {
      swal("異常", `不能生成 ${value} 個錢包`, "error")
      return;
    }

    let tempList = []
    let tempPrivateList = "";
    let tempAddressList = "";
    for (let i = 0; i < value; i++) {
      let result = web3.eth.accounts.create();
      tempList.push(result)
      if (i === 0) {
        tempPrivateList = `${result.privateKey}\n`;
        tempAddressList = `${result.address}\n`
      }
      else {
        if (i == value - 1) {
          tempPrivateList = tempPrivateList + result.privateKey
          tempAddressList = tempAddressList + result.address
        }
        else {
          tempPrivateList = tempPrivateList + `${result.privateKey}\n`
          tempAddressList = tempAddressList + `${result.address}\n`
        }
      }
    }
    setPrivateKeyList(tempList)
    setAllPrivateKey(tempPrivateList);
    setAllAddress(tempAddressList)
    swal("成功", `${value}個錢包已成功生成`, "success")
  }
  return (
    <div style={{
      width: '100vw',
      color: 'black',
      position: 'absolute',
      top: '15vh',
      height: '85vh',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '40vh',
    }}>
      <p style={{ fontSize: '40px', fontWeight: 'bolder' }}>
        批量生成錢包
      </p>
      <p style={{ fontSize: '15px', color: 'gray', display: 'flex' }}>
        生成錢包為離線產生資料<br />
        並無上鍊情形<br />
        所產生之私鑰與地址僅提供本地端顯示<br />
      </p>

      <div className='generateWalletWrapper' style={{ width: '80vw', maxWidth: '800px' }}>
        <div className='addressData' style={{ border: '1px solid black' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
            <p>欲生成的錢包數量 : </p>
            <input type="number" id="walletNumber" style={{ margin: '10px' }} />
            <button style={{ margin: '10px' }} onClick={generateWallet}>生成錢包</button>
          </div>
        </div>
      </div>
      {
        privateKeyList !== null &&

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '80vw', maxWidth: '800px', padding: '15px', fontSize:'15px' }}>
          <div
            style={{ flex: '1', color: allWalletSelected ? 'red' : 'black', cursor: 'pointer' }}
            onClick={allWalletClicked}
          >查看全部錢包</div>
          <div
            style={{ flex: '1', color: allPrivateSelected ? 'red' : 'black', cursor: 'pointer' }}
            onClick={allPrivateClicked}
          >僅查看私鑰</div>
          <div
            style={{ flex: '1', color: allAddressSelected ? 'red' : 'black', cursor: 'pointer' }}
            onClick={allAddressClicked}
          >僅查看地址</div>
        </div>
      }
      {
        privateKeyList !== null && allPrivateSelected &&
        <div>
          <div style={{ width: '80vw', maxWidth: '800px', height: '30vh', overflow: 'scroll', border: '1px solid black' }}>
            <div>
              <CopyToClipboard text={allPrivateKey} onCopy={() => setCopied(true)}>
                <p
                  style={{ cursor: 'pointer', borderBottom: '1px solid black', paddingBottom: '15px' }}
                  onClick={copyAllPrivateKey}>一鍵複製所有私鑰</p>
              </CopyToClipboard>
            </div>
            {
              privateKeyList.map((item, index) => (
                <div key={index} style={{ textAlign: 'left', paddingLeft: '20px' }}>
                  {index + 1} : {item.privateKey}
                </div>
              ))
            }
          </div>
        </div>
      }
      {
        privateKeyList !== null && allAddressSelected &&
        <div>
          <div style={{ width: '80vw', maxWidth: '800px', height: '30vh', overflow: 'scroll', border: '1px solid black' }}>
            <div>
              <p
                style={{ cursor: 'pointer', borderBottom: '1px solid black', paddingBottom: '15px' }}
                onClick={copyAllAddress}>一鍵複製所有地址</p>
            </div>
            {
              privateKeyList.map((item, index) => (
                <div key={index} style={{ textAlign: 'left', paddingLeft: '20px' }}>
                  {index + 1} : {item.address}
                </div>
              ))
            }
          </div>
        </div>
      }
      {
        privateKeyList !== null && allWalletSelected &&
        <div>
          <div style={{ position: 'relative' }}>
            <div>
              <div >
                {privateKeyList.map((item, index) => (
                  <div key={index} style={{ width: '80vw', maxWidth: '800px', height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '10px' }}>
                    <span style={{ textAlign: 'left', paddingLeft: '50px' }}>Wallet {index + 1}</span>
                    <div className="addressRow" style={{ display: 'flex', width: '80vw', maxWidth: '800px', height: '30px', textAlign: 'left', marginBottom: '10px' }}>
                      <div style={{ fontSize: '15px', width: '60px', border: '1px solid black', textAlign: 'center', marginLeft: '10vw' }}>
                        地址
                      </div>
                      <div style={{ border: '1px solid black', overflowX: 'scroll', width: '40vw', paddingLeft: '10px', paddingRight: '10px' }}>
                        {item.address}
                      </div>
                      <div
                        style={{ border: '1px solid black', width: '60px', textAlign: 'center', marginRight: '10vw', cursor: 'pointer' }}
                        onClick={() => copyToClipBoard(item.address)}>
                        複製
                      </div>
                    </div>
                    <div className="addressRow" style={{ display: 'flex', width: '80vw', maxWidth: '800px', height: '30px', textAlign: 'left', marginBottom: '10px' }}>
                      <div style={{ fontSize: '15px', width: '60px', border: '1px solid black', textAlign: 'center', marginLeft: '10vw' }}>
                        私鑰
                      </div>
                      <div style={{ border: '1px solid black', overflowX: 'scroll', width: '40vw', paddingLeft: '10px', paddingRight: '10px' }}>
                        {item.privateKey}
                      </div>
                      <div
                        style={{ border: '1px solid black', width: '60px', textAlign: 'center', marginRight: '10vw', cursor: 'pointer' }}
                        onClick={() => copyToClipBoard(item.privateKey)}>
                        複製
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      }

      <Bottom />
    </div >
  )
}

export default GenerateWallet
