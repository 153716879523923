import React, { useState, useEffect } from 'react';

function Loading() {
    const [loadingText, setLoadingText] = useState("Loading");

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingText((prevLoadingText) => {
                switch (prevLoadingText) {
                    case "Loading":
                        return "Loading.";
                    case "Loading.":
                        return "Loading..";
                    case "Loading..":
                        return "Loading...";
                    case "Loading...":
                        return "Loading";
                    default:
                        return "Loading";
                }
            });
        }, 300);

        return () => clearInterval(interval);
    }, []);

    return <div
        style={{
            fontSize: '40px',
            fontWeight: 'bolder',
            position: 'fixed',
            top: '40vh',
            left: '0vw',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '20vh',
            // borderTop: '3px solid black',
            // borderBottom: '3px solid black',
            // backgroundColor: 'black',
            color:'black',
            display:'flex',
        }}>{loadingText}</div>;
}

export default Loading;