import React from 'react'
import Header from '../components/Header'
import Body from '../components/Body'
import Bottom from '../components/Bottom'

const Home = () => {
    return (
        <div className='home'>
            <div className='Body'>
                <Body />
            </div>
        </div>
    )
}

export default Home
