import React from 'react'
import { Link } from 'react-router-dom'
import WalletConnect from './WalletConnect'

const Header = ({ defaultAccountChange }) => {
    const handleAccount = (value) => {
        defaultAccountChange(value)
        console.log(value)
    }
    return (
        <div style={{
            position: 'fixed',
            width: '100vw',
            height: '15vh',
            backgroundColor: 'black',
            zIndex: '10',
        }}>
            <WalletConnect defaultAccountChange={handleAccount} />
            <span className='Logo' style={{
                position: 'fixed',
                left: '40px',
                top: '5vh',
                width: '120px',
                height: '40px',
                color: 'white',
                zIndex: '1',
            }}>
                <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
                    Logo Here<br />
                </Link>
                <Link to="/member" style={{ textDecoration: 'none', color: 'white' }}>
                    Member Center<br />
                </Link>
            </span>
        </div>
    )
}

export default Header
