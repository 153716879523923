import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import swal
  from 'sweetalert';
const Member = () => {
  let counter = 0;
  const [account, setAccount] = useState(null)

  const getAccount = () => {
    if (account !== null) return;
    let tempAccount = sessionStorage.getItem('account')
    setAccount(tempAccount);
  }

  useEffect(() => {
    signMessage();
  }, [account])

  const [provider, setProvider] = useState(null)
  const [signer, setSigner] = useState(null)

  const signMessage = async () => {
    if (counter > 0) return;
    counter++;
    let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(tempProvider);

    let tempSigner = tempProvider.getSigner();
    setSigner(tempSigner);

    const message = "本签名仅供验证地址使用"
    try {
      let signature = await tempSigner.signMessage(message)
      if (signature) swal("成功", `成功驗證地址 ${account}`)
    } catch {
      swal("失敗", `無法確認您是否為地址 ${account} 的擁有者`)
    }
  }

  getAccount();
  return (
    <div style={{
      width: '100vw',
      color: 'black',
      position: 'absolute',
      top: '15vh',
      height: '85vh',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '40vh',
    }}>
      Member Center
    </div>
  )
}

export default Member
