import React, { useState, useEffect } from 'react'
import Web3 from 'web3'
import { ethers } from 'ethers'
import '../css/EtherBalance.css'
import swal from 'sweetalert'
import Bottom from '../components/Bottom'
import Loading from '../components/Loading'

let BSCMainnetProvider = 'https://mainnet-rpc.hashbit.org';
// let BSCMainnetProvider = 'https://bsc-dataseed.binance.org/';
let web3 = new Web3(new Web3.providers.HttpProvider(BSCMainnetProvider));
const netNow = "HBIT"

const EtherBalance = () => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [totalError, setTotalError] = useState(0);
    const [list, setList] = useState(null);

    const [addressList, setAddressList] = useState([]);
    const [originBalance, setOriginBalance] = useState(null)
    const [balances, setBalances] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [sum, setSum] = useState("計算中");
    const [checking, setChecking] = useState(false)

    const [isAscending, setIsAscending] = useState(false)
    const [isDescending, setIsDescending] = useState(false)

    useEffect(() => {

    }, [balances])

    const checkBalance = () => {
        setIsAscending(false)
        setIsDescending(false)
        if (errorMessage !== null) {
            swal("異常", "請先排除錯誤")
            return;
        }
        console.log("Checking...")
        setChecking(true);

        let result = document.getElementById('addressList');
        if (result.value === "") {
            setChecking(false);
            setBalances(null)
            return;
        }
        let lines = result.value.split('\n')
        getBalances(lines)
    }

    const getBalances = async (value) => {
        console.log("getting balance")
        let balancesArr = [];
        let tempSum = 0;
        for (let i = 0; i < value.length; i++) {
            const address = value[i];
            const balance = await web3.eth.getBalance(address);
            const etherBalance = web3.utils.fromWei(balance, 'ether');
            balancesArr.push({ address, balance: etherBalance });
            await new Promise(resolve => setTimeout(resolve, 10)); // waits for 0.01 sec before proceeding to the next address
            tempSum += Number(etherBalance);
        }

        setSum(tempSum)
        setBalances(balancesArr);
        setOriginBalance(balancesArr);
        setIsLoading(false);
        setChecking(false);
    }

    const ascending = () => {
        setIsDescending(false)
        setIsAscending(true);
        let tempBalances = balances;
        tempBalances.sort((a,b) => a.balance - b.balance)
        console.log(tempBalances)
        setBalances(tempBalances)
    }
    const descending = () => {
        setIsAscending(false)
        setIsDescending(true);
        let tempBalances = balances;
        tempBalances.sort((a,b) => b.balance - a.balance)
        console.log(tempBalances)
        setBalances(tempBalances)
    }

    const deleteFault = () => {
        let result = document.getElementById('addressList');
        let lines = result.value.split('\n')
        let tempAddressList = []
        let tempAddressExist = new Map();

        lines.forEach((line, index) => {
            if (line.length === 42) {
                if (web3.utils.isAddress(line)) {
                    if (tempAddressExist.get(line) === undefined) {
                        tempAddressExist.set(line, true)
                        tempAddressList.push(line)
                    }
                }
            }
        });
        let tempString = ""

        tempAddressList.map((item, index) => {
            if (index !== tempAddressList.length - 1)
                tempString = tempString.concat(`${item}\n`)
            else
                tempString = tempString.concat(`${item}`)
        })

        result.value = tempString;
        setTotalError(0)
        setErrorMessage(null);
    }

    const handleTextareaChange = (value) => {
        const lines = value.target.value.split('\n');

        if (lines.length === 1) {
            // 如果沒資料的時候
            if (lines[0].length === 0) {
                setTotalError(0)
                setErrorMessage(null);
                return;
            }
        }
        let errorList = []
        let tempAddressExist = new Map();
        let errorCounter = 0;

        lines.forEach((line, index) => {
            if (line.length !== 42) {
                let tempError = `Line ${index + 1}: ${line} : 無效的錢包地址(長度錯誤)`
                errorList.push(tempError)
                errorCounter++;
            } else {
                if (web3.utils.isAddress(line)) {
                    if (tempAddressExist.get(line) !== undefined) {
                        let tempError = `Line ${index + 1}: ${line} : 重複的錢包地址`
                        errorCounter++;
                        errorList.push(tempError)
                    }
                    if (tempAddressExist.get(line) === undefined)
                        tempAddressExist.set(line, true)

                } else {
                    let tempError = `Line ${index + 1}: ${line} : 無效的錢包地址(拼寫錯誤)`
                    errorList.push(tempError)
                    errorCounter++;
                }
            }
            if (errorCounter === 0) {
                setTotalError(0)
                setErrorMessage(null);
            } else {
                setTotalError(errorCounter);
                setErrorMessage(errorList)
            }
        });
    }
    return (
        <div style={{
            width: '100vw',
            color: 'black',
            position: 'absolute',
            top: '15vh',
            height: '85vh',
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '40vh',
        }}>
            <p style={{ fontSize: '40px', fontWeight: 'bolder' }}>
                批量檢查平台幣餘額
            </p>
            <p style={{ fontSize: '20px', color: 'gray' }}>
                (BSC鍊： 平台幣為 BNB)<br />
                (OKC鍊： 平台幣為 OKT)<br />
            </p>
            <p style={{ fontSize: '12px', color: 'black', display: 'flex', marginLeft: '20px' }}>
                當前網路:{netNow} <br />
                當前節點:{BSCMainnetProvider}
            </p>
            <div className='transferWrapper' style={{ width: '80vw', maxWidth: '800px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <p style={{ fontSize: '15px', color: 'gray', textAlign: 'left', flex: '1', padding: '5px' }}>
                        請輸入欲檢查 "平台幣" 餘額之地址<br />
                        一行一地址
                    </p>
                    <button onClick={checkBalance} disabled={isLoading}>檢查餘額</button>
                </div>
                {
                    totalError !== 0 &&
                    <div
                        style={{
                            borderRadius: '5px',
                            border: '1px solid red',
                            width: '80vw',
                            maxWidth: '800px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            color: 'red',
                            fontSize: '12px',
                            textAlign: 'left',
                            paddingLeft: '10px'
                        }}>
                        <p>
                            總共有 {totalError} 個錯誤
                            {errorMessage.map(item => (
                                <li key={item}>{item}</li>
                            ))}
                        </p>
                        <p style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={deleteFault}>一鍵刪除無效地址</p>
                    </div>
                }
                <div style={{ position: 'relative' }}>
                    <textarea
                        rows="10"
                        id='addressList'
                        style={{
                            border: '1px solid black',
                            width: '80vw',
                            maxWidth: '800px',
                        }}
                        onChange={handleTextareaChange}
                        placeholder="請輸入欲檢查 [平台幣] 餘額之地址，一行一地址"
                    />
                </div>
                {checking === true &&
                    <div>
                        <Loading />
                    </div>}
                {
                    balances !== null &&
                    <div>
                        <p>總平台幣餘額：{sum}</p>
                        <div style={{
                            overflowX: 'scroll',
                            width: '80vw',
                            maxWidth: '800px',
                            border: '1px solid black',
                        }}>
                            <div className='resultTitleRow' style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '800px',
                                borderBottom: '1px solid black',
                                color: 'gray',
                                fontWeight: 'bold'
                            }}>
                                <span className='rowID'>ID</span>
                                <span className='rowAddress'>地址</span>
                                <div className='rowBalance' style={{display: 'flex', justifyContent: 'center'}}>
                                    <span>
                                        餘額
                                    </span>
                                    <div style={{alignItems:'center',}}>

                                        <span style={{
                                            width:'10px',
                                            height:'8px',
                                            cursor:'pointer',
                                            color: isAscending ? 'blueviolet': 'gray'
                                        }} onClick={ascending}>▲</span>

                                        <span style={{
                                            width:'10px',
                                            height:'8px', 
                                            cursor:'pointer',
                                            color: isDescending ? 'blueviolet': 'gray'
                                        }} onClick={descending}>▼</span>
                                    </div>
                                </div>
                            </div>
                            <div className='balanceResult' style={{
                                height: '40vh',
                                width: '800px',
                                overflowY: 'scroll',
                                fontSize: '15px',
                            }} >
                                {
                                    balances !== null &&
                                    balances.map(
                                        (item, index) => (
                                            <div key={index} style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid black' }}>
                                                <div className='rowID' style={{ padding: '20px' }}>
                                                    {index + 1}
                                                </div>
                                                <div className='rowAddress' style={{ padding: '20px' }}>
                                                    {item.address}
                                                </div>
                                                <div className='rowBalance' style={{ padding: '20px', wordWrap: 'break-word' }}>
                                                    {item.balance}
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                }
                <Bottom />
            </div>
        </div>
    )
}

export default EtherBalance
