import logo from './logo.svg';
import './App.css';
import Home from './pages/Home'
import Header from './components/Header';
import TransferEther from './pages/TransferEther';
import EtherBalance from './pages/EtherBalance';
import TransferEtherBack from './pages/TansferEtherBack';
import Member from './pages/Member';
import GenerateWallet from './pages/GenerateWallet';
import BatchTransferPublic from './pages/BatchTransferPublic';
import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  const [defaultAccount, setDefaultAccount] = useState(null)

  const handleAccount = (value) => {
    setDefaultAccount(value);
    console.log(`地址為: ${value}`)
  }
  useEffect(() => {
    console.log(defaultAccount)
  }, [defaultAccount])


  return (
    <div className="App">
      {/* {component} */}
      <Router>
        <Header defaultAccountChange={handleAccount} />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/transferether' element={<TransferEther />} />
          <Route path='/etherbalance' element={<EtherBalance />} />
          <Route path='/transferetherback' element={<TransferEtherBack />} />
          <Route path='/generatewallet' element={<GenerateWallet />} />
          <Route path='/batchtransferpublic' element={<BatchTransferPublic defaultAccount={defaultAccount} />} />
          <Route path='/member' element={<Member />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
